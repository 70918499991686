<i18n src="@/i18n/error.json" />
<i18n src="@/i18n/backoffice/auth/login.json" />
<template>
	<div class="min-h-screen flex items-center justify-center py-6 sm:py-12 px-2 sm:px-6 lg:px-8">
		<section class="p-4 sm:p-8 max-w-md w-full bg-gray-50 rounded-sm shadow">
			<!-- header !-->
			<header>
				<!-- Logo !-->
				<div class="w-52 mx-auto mt-4">
					<img :src="$domain.logo" alt="Logo">
				</div>
				<!-- Title !-->
				<h2 class="mt-6 text-center leading-9 text-gray-700 text-sm font-bold">
					<span>{{ $t('login.entrar_em_sua_conta') }}</span>
				</h2>
			</header>
			<!-- /header !-->
			<!-- content !-->
			<div class="mt-2">
				<input type="hidden" name="remember" value="true"/>
				<!-- Username !-->
				<label class="block">
					<span class="text-sm">{{ $t('login.username') }}</span>
					<FormInput ref="email" data-cy="email" v-model="fields.email" :validation="v$.fields.email" class="mt-1"/>
				</label>
				<!-- Password !-->
				<label class="block mt-4">
					<span class="text-sm">{{ $t('login.password') }}</span>
					<FormInput ref="password" data-cy="password" v-model="fields.password" :validation="v$.fields.password" type="password" class="mt-1" @enter="handleSend"/>
				</label>
				<!-- Options !-->
				<div class="mt-6 flex items-center justify-between">
					<div class="flex items-center">
						<input id="remember_me" type="checkbox" class="form-checkbox border-gray-300 shadow-sm h-4 w-4 text-turquoise rounded" v-model="fields.remember_me"/>
						<label for="remember_me" class="ml-2 block text-sm leading-5 select-none">
							{{ $t('login.manter_conectado') }}
						</label>
					</div>
					<div class="text-sm leading-5">
						<router-link to="/password/reset"
						             class="font-medium hover:opacity-75 focus:outline-none underline transition ease-in-out duration-150">
							{{ $t('login.esqueceu_senha') }}
						</router-link>
					</div>
				</div>
				<!-- /Options !-->
				<div class="mt-6">
					<button class="w-full py-3 text-white font-medium bg-turquoise rounded shadow opacity-75 hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-turquoise" @click.prevent="handleSend">
						{{ $t('login.entrar') }}
					</button>
				</div>
			</div>
			<!-- /content !-->
		</section>
		<Loading v-if="isLoading"/>
	</div>
</template>

<script>
import LoginMixin from "./mixins/login.js"

import Loading from "@/components/Loading.vue"
import FormInput from "@/components/FormInput.vue"

export default {
	name: "LoginPage",
	mixins: [LoginMixin],
	components: {
		Loading, FormInput
	},
	methods: {
		/**
		 * Handle send
		 * @returns {Promise<void>}
		 */
		async handleSend() {
			if(await this.send()) {
                this.$alertSuccess(this.$t('login.redirecting'))
                setTimeout(() => window.location = '/' , 1000)
            }
		}
	}
}
</script>

import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

import LoginPage from "@/pages/backoffice/auth/LoginPage"
import PasswordRecoveryPage from "@/pages/backoffice/auth/PasswordRecoveryPage"
import PasswordRenewPage from "@/pages/backoffice/auth/PasswordRenewPage"

const routes = [
    {
        path: '/',
        component: LoginPage
    },
    {
        path: '/login',
        component: LoginPage
    },
    {
        path: '/password/reset',
        component: PasswordRecoveryPage
    },
    {
        path: '/password/renew',
        component: PasswordRenewPage
    },
    {
        path: '*',
        component: LoginPage
    }
]

export default new VueRouter({
    mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})

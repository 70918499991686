import "../bootstrap.js"
import Vue from "vue"
import App from "@/Auth.vue"
import store from "@/auth_store.js"
import i18n from "@/i18n.js"
import router from "@/routes/backoffice/routes_auth.js"

import localePlugin from "@/plugins/locale.js"
import domainPlugin from "@/plugins/domain.js"
import userPlugin from "@/plugins/user.js"
import tailwindPlugin from "@/plugins/tailwind.js"
import VueTheMask from 'vue-the-mask'
import "@/helpers.js"

Vue.use(localePlugin)
Vue.use(domainPlugin)
Vue.use(tailwindPlugin)
Vue.use(userPlugin)
Vue.use(VueTheMask)

Vue.config.productionTip = false;

window.API_PREFIX = "/backoffice"

new Vue({
    render: h => h(App),
    router,
    store,
    i18n
}).$mount("#App")

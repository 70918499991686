<i18n src="@/i18n/backoffice/auth/password_renew.json" />
<template>
	<section class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
		<div class="p-4 sm:p-8 max-w-md w-full bg-gray-50 border border-gray-700">
			<!-- header !-->
			<header>
				<!-- logo !-->
				<div class="w-52 mx-auto mt-4">
					<img :src="$domain.logo" alt="Logo">
				</div>
				<!-- title !-->
				<h2 class="mt-6 text-center text-xl leading-6 font-extrabold text-gray-900">
					{{ $t('password_renew.recovery_access') }}
				</h2>
				<p class="mt-2 text-center text-sm leading-5 text-gray-600">
					{{ $t('ou') }}
					<router-link to="/" class="font-medium text-turquoise hover:opacity-75 focus:outline-none focus:underline transition ease-in-out duration-150">
						{{ $t('password_renew.access_with_your_credentials') }}
					</router-link>
				</p>
			</header>
			<!-- /header !-->
			<!-- content !-->
			<form class="mt-8" action="/register" method="POST">
				<!-- Credentials !-->
				<div class="rounded-md">
					<label>
						<input aria-label="Email address" name="email" type="email" required
						       class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						       :placeholder="$t('password_renew.email')"
						       v-model="fields.email"
						/>
					</label>
					<label class="-mt-px block">
						<input aria-label="Password" name="password" type="password" required
						       class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						       :placeholder="$t('password_renew.password')"
						       v-model="fields.password"
						/>
					</label>
					<label class="-mt-px block">
						<input aria-label="Password Confirmation" name="password_confirmation" type="password" required
						       class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
						       :placeholder="$t('password_renew.password_confirmation')"
						       v-model="fields.password_confirmation"
						/>
					</label>
				</div>
				<!-- / Credentials !-->
				<!-- Actions !-->
				<div class="mt-6">
					<button class="w-full py-3 text-white font-medium bg-turquoise rounded shadow hover:opacity-75" @click.prevent="handleSend">
						{{ $t('password_renew.change') }}
					</button>
				</div>
			</form>
			<!-- /content !-->
		</div>

		<Loading v-if="isLoading" overlay-opacity="75"/>
	</section>
</template>

<script>
import PageMixin from "./mixins/password_renew"

import Loading from "@/components/Loading"
import FormInput from "@/components/FormInput"
import DefaultButton from "@/components/DefaultButton"

export default {
	name: "PasswordRecoveryPage",
	mixins: [PageMixin],
	components: {
		DefaultButton, Loading, FormInput
	},
	methods: {
		handleSend() {
			this.send()
		}
	}
}
</script>
import Auth from "@/API/Auth.js"
import AlertMixin from "@/mixins/alert.js"
import LoadingMixin from "@/mixins/loading.js"

import ValidationMixin from "@/mixins/validation.js"
import { required, email } from "@vuelidate/validators"

export default {
    mixins: [ LoadingMixin, AlertMixin, ValidationMixin ],
    data(){
        return {
            fields: {
                email: null
            }
        }
    },
    validations: {
        fields: {
            email: {
                required,
                email
            }
        }
    },
    methods: {
        validate() {
            this.v$.$touch()

            console.debug('Auth/password_recovery@validade validating')

            return !this.v$.$invalid
        },
        async send(){
            this.v$.$touch()
            if (this.v$.$invalid) return false

            console.debug('Auth/password_recovery@send password recovery')

            this.loading()
            const [err, res] = await Auth.passwordRecovery(this.fields)
            this.loaded()

            if(err) {
                console.error(err)

                if(err.response?.data.errors?.email.length) {
                    this.$alertError(err.response?.data.errors.email[0])
                }

                return false
            }

            this.$alertSuccess(res.data.message)

            return true
        }
    }
}
